import $ from 'jquery';
import AOS from 'aos';

$(document).ready(function() {

    /* MATCH HEIGHT EQUAL HEIGHT

    $('.matchHeight').matchHeight();
     */

    /* SWITCHER
    $('.toggle-btn').click(function() {
        $(this).parent().toggleClass('active').siblings().removeClass('active');
        //$(this).parent().find('.single_switcher__active').toggleClass('active').siblings().removeClass('active');
    });
     */

    /* AOS ANIMATION FUNCTION

    $(function() {
        AOS.init();
    });
     */


});

document.addEventListener("DOMContentLoaded", function() {
	if (window.innerWidth > 768) {
		const figures = document.querySelectorAll("figure[data-banner-url]");
		let isAlt = false;
		figures.forEach(figure => {
			figure.addEventListener("mouseover", function() {
				const bannerUrl = this.getAttribute("data-banner-url");
				const section = document.getElementById("section-mission");
				const background = isAlt ? section.querySelector('.background') : section.querySelector('.background-alt');
				background.style.backgroundImage = `url(${bannerUrl})`;
				background.style.opacity = 1;
			});
			figure.addEventListener("mouseout", function() {
				const section = document.getElementById("section-mission");
				const background = isAlt ? section.querySelector('.background') : section.querySelector('.background-alt');
				background.style.opacity = 0;
				isAlt = !isAlt;
			});
		});
	}
});



document.addEventListener('scroll', function() {
	const containers = document.querySelectorAll('.cf_content_classic');
	const scrollY = window.scrollY;
	const speed = -0.5; // Ajuste la vitesse de l'élément ::after

	containers.forEach(container => {
		container.style.setProperty('--scroll', `${scrollY * speed}px`);
	});
});

$('input[type="submit"]').addClass('btn btn-orange btn-clippy');

(function () {
	const MIN_DISPLAY_TIME = 1500;
	const startTime = Date.now();

	function launchLoader() {
		const elapsed = Date.now() - startTime;
		const remaining = MIN_DISPLAY_TIME - elapsed;
		setTimeout(() => {
			document.body.classList.add('loaded');
		}, remaining > 0 ? remaining : 0);
	}

	// S'assurer que le loader est visible au départ
	document.body.classList.remove("loaded");

	// Cas normal : window.load pas encore passé
	if (document.readyState !== "complete") {
		window.addEventListener("load", launchLoader);
	} else {
		// Cas home : window.load déjà passé, lancer direct
		launchLoader();
	}
})();


